import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {  Button, Form, Jumbotron, Modal } from 'react-bootstrap';
import api from '../conexao.js';
import {useNavigate, Link} from 'react-router-dom';
import '../../App.css';
import logOut from '../logOut';

function Medicamento() {
  const nave= useNavigate();

    const {register, handleSubmit} = useForm( );  //REGISTRANDO AS FUNÇÕES DO USEFORM
    
    // const [valor, setValor] = useState();
    const [medicamento, setMedicamento] = useState([]);  
    const [mostrarModal, setMostrarModal] = useState(false);
    const [medicdelete, setMedcDelete] = useState();
    const [nomemedicamento, setNomeMedicamento] = useState();
    const [MSG, setMSG] = useState();
    const [modalMSG, setModalMSG] = useState(false);

    const onSubmit = (data) => {

    api.get('/medicamento', { 
      params: {           //BUSCA POR PARAMETRO ? NOME
        nome: data.NOME
      }}).then( result => {
      setMedicamento(result.data) // joga a resposta em medicamento
      }).catch((error) => logOut(error))
    }

    function handleCadMedicamento(){
      nave('/medicamento/cadmedicamento');
       
      
    }

    function handleMostrarModal(){
      setMostrarModal(true);

    }

    function handleFecharModal(){
      setMostrarModal(false);
    }    


    async function handleExcluir(cod_delete){
             
        const response = await api.delete('/medicamento/'+ cod_delete);
        handleFecharModal(false); 
       // window.location.reload();
        // alert('Medicamento Excluido!'); 
        setMSG('Medicamento Excluido!');
        setModalMSG(true)  
       

    }

    return (
      <div>
         
         <div className='Jumbotron'>
        <h1><center> Casdastro de Medicamentos </center></h1>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Control 
                  placeholder="Digite o Medicamento"
                  type="text"
                  name="NOME"
                  Style="text-transform:uppercase;"
                  {...register('NOME')}
                  required  
                  />
                  <br />
                  <Button variant="light" type="submit">
                          Buscar Medicamento </Button> <span />
                  <Button variant="success"  onClick={() => {handleCadMedicamento()}}>Novo Medicamento</Button>
                                           {/* defina type submit para que ele troca a route */}         
            </Form> 
         <br />
    <table className="table">
    <thead>
        <tr>
            <th scope="col">Cod.</th>
            <th scope="col">Nome</th>
            <th scope="col"></th>
        </tr>
       
      </thead>
        <tbody>
          {medicamento.map(customer => ( // percorre todos o array e joga na tela em formato de tabela
                              <tr key={customer.CODIGO}>
                                  <td>{customer.CODIGO}</td>
                                  <td>{customer.NOME}</td>
                                  <td> <Link to={"/medicamento/editarmedicamento/" + customer.CODIGO}><Button variant="warning" >Editar</Button></Link>
                                  <span> </span>
                                  <Button variant="danger" onClick={ () => {
                                    setMedcDelete(customer.CODIGO)
                                    setNomeMedicamento(customer.NOME)
                                    handleMostrarModal()
                                  }}>Excluir</Button></td>
                              </tr>
                          ))}
        </tbody>
        </table>
              
    
            </div>
            <Modal show={mostrarModal} backdrop="static">
              <Modal.Header>
                <h2> Excluir Medicamento? </h2>
              </Modal.Header>
              <Modal.Body>
                  Excluir medicamento: <strong>{nomemedicamento}</strong>
              </Modal.Body>
              <Modal.Footer>
               <Button variant="danger" onClick={() => handleExcluir(medicdelete)}>Excluir</Button>
                <Button variant="secondary" onClick={() => handleFecharModal()}>Voltar</Button>
              </Modal.Footer>
            </Modal>

              <Modal show={modalMSG}>
                <Modal.Header> 
                    <h3>{MSG}</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
                </Modal.Footer>
            </Modal> 
      </div>
    );

}

export default Medicamento; //retornar funcao sempre sem ()