import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {  Button, Form, Jumbotron, Modal } from 'react-bootstrap';
import api from '../conexao.js';
import {useNavigate, Link} from 'react-router-dom';
import '../../App.css';
import logOut from '../logOut';

export default function Medico(){

    const nave= useNavigate();

    const {register, handleSubmit} = useForm( );  //REGISTRANDO AS FUNÇÕES DO USEFORM

    const [medico, setMedico] = useState([]);  
    const [mostrarModal, setMostrarModal] = useState(false);
    const [meddelete, setMedDelete] = useState();
    const [nomemedico, setNomeMedico] = useState();
    const [MSG, setMSG] = useState();
    const [modalMSG, setModalMSG] = useState(false);

    const onSubmit = (data) => {

        api.get('/medico', { 
          params: {           //BUSCA POR PARAMETRO ? NOME
            nome: data.NOME
          }}).then( result => {
          setMedico(result.data) // joga a resposta no state medico
          }).catch((error) => logOut(error))
        }

        function handleCadMedico(){
          nave('/medico/cadmedico');
         }
     
         function handleMostrarModal(){
           setMostrarModal(true);
     
         }
     
         function handleFecharModal(){
           setMostrarModal(false);
         }    

         async function handleExcluir(cod_delete){
             
            const response = await api.delete('/medico/'+ cod_delete);
            handleFecharModal(false); 
            // alert('Médico Excluido!'); 
            setMSG('Médico Excluido!');
            setModalMSG(true)  
            setMedico([])
            
        }

        

    return (

        <div>
            <div className='Jumbotron'>
        
            <center><h1>Cadastro de Médicos</h1></center>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Control 
                type="text"
                placeholder="DIGITE O NOME" 
                name="NOME" 
                Style="text-transform:uppercase;"
                {...register('NOME')}
                required  
                />
                <br />
                <Button variant="light" type="submit">
                  Buscar</Button> <span />
                
                <Button variant="success"  onClick={() => {handleCadMedico()}}>Novo</Button>
                                     
            </Form>
            <br />
    <table className="table">
    <thead>
        <tr>
            <th scope="col">Cod.</th>
            <th scope="col">Nome</th>
            <th scope="col">Cidade</th>
            <th scope="col">Telefone</th>
            <th scope="col">Celular</th>
            <th scope="col">CRM</th>
            <th scope="col"></th>
        </tr>
       
      </thead>
        <tbody>
          {medico.map(customer => ( // percorre todos o array e joga na tela em formato de tabela
                              <tr key={customer.CODIGO}>
                                  <td>{customer.CODIGO}</td>
                                  <td>{customer.NOME}</td>
                                  <td>{customer.CIDADE}</td>
                                  <td>{customer.TEL}</td>
                                  <td>{customer.CEL}</td>
                                  <td>{customer.CRM}</td>
                                  <td> <Link to={"/medico/editarmedico/" + customer.CODIGO}>
                                          <Button variant="warning" >
                                            Editar
                                          </Button>
                                        </Link>
                                  <span> </span>
                                  <Button variant="danger" onClick={ () => {
                                    setMedDelete(customer.CODIGO)
                                    setNomeMedico(customer.NOME)
                                    handleMostrarModal()
                                  }}>Excluir</Button></td>
                              </tr>
                          ))}
        </tbody>
        </table>
        </div>

            <Modal show={mostrarModal} backdrop="static">
              <Modal.Header>
                <h2> Excluir Medico? </h2>
              </Modal.Header>
              <Modal.Body>
                  Excluir Medico: <strong>{nomemedico}</strong>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={() => handleExcluir(meddelete)}>Excluir</Button>
               <Button variant="secondary" onClick={() => handleFecharModal()}>Voltar</Button>
              </Modal.Footer>
            </Modal>

              <Modal show={modalMSG}>
                <Modal.Header> 
                    <h3>{MSG}</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
                </Modal.Footer>
            </Modal> 
        </div>
    )
}