
export default function logOut(error){

    
    if (error.message.includes('401')) {
        // alert('Sessão Expirada! Faça o Login Novamente.')
        window.location = '/'
        localStorage.removeItem('token')
        return('Sessão Expirada! Faça o Login Novamente.')
    } else
    if (error.message.includes('404')){
        // alert('EndPoint não Existe!')
        return('EndPoint não Existe!');
    } else
    if (error.message.includes('Network Error')){
        // alert('Servidor Fora do ar, Contate o Administrador.')
        return('Erro de Conexão, Contate o Administrador.')
    } else
    if (error.message.includes('403')){
        // alert('Usuário ou Senha Incorretos.')
        return('Usuário ou Senha Incorretos.');
    }else 
    if (error.message.includes('ERR_CONNECTION_RESET')) {
        return(`${error.message}, Contate o Administrador!`)
    } else
    { 
        
        return(`${error.message}, Contate o Administrador!`)
    }
}