import React, { useState }from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Button, Form} from 'react-bootstrap';
import api from '../conexao.js';
import '../../App.css';
import { useNavigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import logOut from '../logOut';

export default function CadPaciente(){

        const nave= useNavigate();

const {register, handleSubmit} = useForm( );  //REGISTRANDO AS FUNÇÕES DO USEFORM
const [datanasc, setDataNasc] = useState(null);
const [exibirModal, setExibirModal] = useState(false); // const para definir se a modal aparece ou nao
const [cep, setCEP] = useState();
const [tel, setTEL] = useState();
const [cel, setCEL] = useState();
const [cpf, setCPF] = useState();
const [cpf_conjuge, setCPF_CONJUGE] = useState();
const [modalMSG, setModalMSG] = useState(false);
const [MSG, setMSG] = useState();



function handleFecharModal(){
        setExibirModal(false); // inicia faalse
        nave('/paciente/paciente'); // vai para pagina anterior
}

function handleVoltar(){
        nave('/paciente/paciente');
}

var cepnum = String;
var telnum = String;
var celnum = String;
var cpfnum = String;
var cpfconjnum = String;


 const onSubmit = (data) => {
         
        if (cep) {
                cepnum = cep;
                cepnum = cepnum.replace(/[^0-9.]/g, ""); // remove o que nao é numero do CEP
        }
        if  (tel)  {
                telnum = tel;
                telnum = telnum.replace(/[^0-9]+/g, "");
        }
        if (cel) {
                celnum = cel;
                celnum = celnum.replace(/[^0-9]+/g, "");
        }
        if (cpf) {
                cpfnum = cpf;
                cpfnum = cpfnum.replace(/[^0-9]+/g, "");
        }
        if (cpf_conjuge) {
                cpfconjnum = cpf_conjuge;
                cpfconjnum = cpfconjnum.replace(/[^0-9]+/g, "");
        }
        
        const response = api.post('/paciente', {
                COD_EC: parseInt(data.COD_EC),
                NOME: data.NOME.toUpperCase(),
                SEXO: data.SEXO,
                ENDERECO: data.ENDERECO.toUpperCase(),
                BAIRRO: data.BAIRRO.toUpperCase(),
                CIDADE: data.CIDADE.toUpperCase(),
                CEP: cepnum,
                TEL: telnum,
                CEL: celnum,
                CPF: cpfnum,
                IDENT: data.IDENT.toUpperCase(),
                DATA_NASC: datanasc,
                CONJUGUE: data.CONJUGUE.toUpperCase(),
                CPF_CONJUGE: cpfconjnum,
                PLANO: data.PLANO.toUpperCase(),
                NATURALIDADE: data.NATURALIDADE.toUpperCase(),
                PROFISSAO: data.PROFISSAO.toUpperCase(),
                INDICADO_POR: data.INDICADOPOR.toUpperCase(),
                OBS: data.OBS
        }).catch(        (error) => {
                setMSG(logOut(error))
                setModalMSG(true)
            }) 
        .then(setExibirModal(true))
        // .catch((error) => logOut(error))
        
       
}   
        
    return(
        
        // <div className="w-50 p-3 mx-auto">
        <div className="w-90 p-3 mx-auto">
                <div className='Jumbotron'>
                <center> <h1> Cadastro Paciente </h1> </center>
               <br />
            <div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-90 p-3 mx-auto">
            <div className="form-row">
                <div className="form-group col-md-6" >
                        Nome:
                        <Form.Control 
                        Style="text-transform:uppercase;"  
                        size="sm" 
                        type="text" 
                        name="NOME"  
                        maxLength={45}
                        {...register('NOME')} 
                        required 
                        />
                </div>
           
                <div className="form-group col-md-2">
                        Sexo:
                        <Form.Control size="sm" as="select" name="SEXO" {...register('SEXO')}>
                            <option value="M">MASCULINO</option>
                            <option value="F">FEMININO</option>                
                        </Form.Control>
                </div>
            </div>
            <div className="form-row">
            <div className="form-group col-md-6">
                        Endereço:
                        <Form.Control Style="text-transform:uppercase;" maxLength={45} size="sm" type="text" name="ENDERECO"  {...register('ENDERECO')}/>
                </div> 
                <div className="form-group col-md-6">
                        Bairro:
                        <Form.Control Style="text-transform:uppercase;" maxLength={45} size="sm" type="text" name="BAIRRO" {...register('BAIRRO')}/>
                </div>   
            </div>

            <div className="form-row">
            <div className="form-group col-md-6">
                        Cidade:
                        <Form.Control Style="text-transform:uppercase;" maxLength={45} size="sm" type="text" name="CIDADE" {...register('CIDADE')}/>
                </div> 

 
            </div>
            <div className="form-group col-md-2">
                        CEP:
                        <br/>
                       
                        <InputMask  
                                mask="99999-999" 
                                size="8" 
                                name="CEP"  
                                {...register('CEP')} 
                                value={cep}
                                onChange={(e) => setCEP(e.target.value)}  // unica maneira para registrar o cep no State
                                alwaysShowMask={false}
                        />
                
                </div>  

            <div className="form-row">
            <div className="form-group col-md-4">
                        Tel.:
                        <br />
                        <InputMask
                                mask="(99)9999-9999"
                                size="24"
                                name="TEL"
                                {...register('TEL')}
                                value={tel}
                                onChange={(e) => setTEL(e.target.value)}
                                alwaysShowMask={false}
                        />
                </div> 
                <div className="form-group col-md-4">
                        Cel.:
                        <br />
                        <InputMask
                                mask="(99)99999-9999"
                                size="24"
                                name="CEL"
                                {...register('CEL')}
                                value={cel}
                                onChange={(e) => setCEL(e.target.value)}
                                alwaysShowMask={false}
                        />
                </div>
                <div className="form-group col-md-4">
                        CPF:
                        <br />
                        <InputMask
                                mask="999.999.999-99"
                                size="24"
                                name="CPF"
                                {...register('CPF')}
                                value={cpf}
                                onChange={(e) => setCPF(e.target.value)}
                                alwaysShowMask={false}
                        />
                </div>  
            </div>
            

            <div className="form-row">
            <div className="form-group col-md-4">
                        RG:
                <Form.Control Style="text-transform:uppercase;" maxLength="15" size="sm" type="text" name="IDENT" {...register('IDENT')}/>
                </div> 
                <div className="form-group col-md-4">
                        Data Nascimento:
                        <br/>
                         <DatePicker                         
                        // locale="pt"
                        
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Selecione a data"
                        // withPortal
                        selected={datanasc}
                        onChange={setDataNasc}                         
                        name="DATA_NASC"
                        // {...register('DATA_NASC')}
                        /> 
                        
                        
                </div>
                <div className="form-group col-md-4">
                        Estado Civil:
                        <Form.Control as="select" name="COD_EC" size="sm" {...register('COD_EC')}>
                            <option value="2">CASADO(A)</option>
                            <option value="4">DIVORCIADO(A)</option>
                            <option value="6">NENHUM</option>
                            <option value="5">SEPARADO(A)</option>
                            <option value="1">SOLTEIRO(A)</option>
                            <option value="3">VIÚVO(A)</option>
                        </Form.Control>
                </div>  
            </div>

            <div className="form-row">
            <div className="form-group col-md-4">
                        Conjuge:
                        <Form.Control Style="text-transform:uppercase;" maxLength={45} size="sm" type="text" name="CONJUGUE" {...register('CONJUGUE')}/>
                </div> 
                <div className="form-group col-md-4">
                        CPF Conjuge:
                        <br/>
                        <InputMask
                                mask="999.999.999-99"
                                size="24"
                                name="CPF_CONJUGE"
                                {...register('CPF_CONJUGE')}
                                value={cpf_conjuge}
                                onChange={(e) => setCPF_CONJUGE(e.target.value)}
                                alwaysShowMask={false}
                        />
                        

                </div>
                <div className="form-group col-md-4">
                        Plano:
                        <Form.Control Style="text-transform:uppercase;" maxLength={45} size="sm" type="text" name="PLANO" {...register('PLANO')}/>
                </div>  
            </div>

            <div className="form-row">
                <div className="form-group col-md-4">
                        Natualidade:
                        <Form.Control Style="text-transform:uppercase;" maxLength={45} size="sm" type="text" name="NATURALIDADE" {...register('NATURALIDADE')}/>
                </div> 
                <div className="form-group col-md-4">
                        Profissão:
                        <Form.Control Style="text-transform:uppercase;" maxLength={45} size="sm" type="text" name="PROFISSAO"  {...register('PROFISSAO')}/>
                </div>
                <div className="form-group col-md-4">
                        Indicado por:
                        <Form.Control Style="text-transform:uppercase;" maxLength={45} size="sm" type="text" name="INDICADOPOR" {...register('INDICADOPOR')}/>
                </div>  
            </div>
            <div className="form-row">
                <div className="form-group col-md-12">
                        Observação:
                        <Form.Control as="textarea" maxLength={120} rows="3" size="sm" name="OBS" {...register('OBS')}/>
                </div> 

            </div>
            <br />
            <div className="form-row" >
                <div className="form-group col-md-10">
                    <Button 
                //     type="button" 
                    className="btn btn-primary" 
                    type="submit" 
                //     onClick={() => {handleConecta()}}
                    >
                        Gravar</Button>
                {/* </div>
                <div className="form-group col-md-2"> */}
                    <Button type="button" 
                    className="btn btn-secondary" 
                     onClick={() => {handleVoltar()}}
                    >
                Cancelar</Button>
                </div>    
            </div>   
          </div>
            </Form> 
           </div> 
            </div> 
        <Modal show={exibirModal} onHide={handleFecharModal} backdrop="static">
                <Modal.Header>
                        <h2> Cadastro Paciente </h2>
                </Modal.Header>
                <Modal.Body>
                        Paciente Gravado!
                </Modal.Body>
                <Modal.Footer>
                        <Button variante="success" onClick={() => handleFecharModal()}> Ok </Button>
                </Modal.Footer>
        </Modal>
        <Modal show={modalMSG}>
                <Modal.Header> 
                    <h3>{MSG}</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
                </Modal.Footer>
            </Modal> 

        </div>
        
    )
};


