import React from 'react';
import { useState } from 'react';
import { Form, Button, Modal} from 'react-bootstrap';
import Axios from 'axios';
import App from './App';
import logo from './img/medsolutions.jpg'; //teste

export default function Login(){

    const [userName, setUserName] = useState();
    const [password, setPassword] = useState();
    const [mostraModalErro, setMostraModalErro] = useState(false);
    const [msgErro, setMsgErro] = useState();
    var tokenBack;
    const [app, setApp] = useState(false);
    var tentativa;

    async function handleLogin(){

       const autenticaLogin = async () => { 
            
            if (!userName) {
                    setMsgErro('Preecha o Usuário!')
                    setMostraModalErro(true)

            }else {

                // const result = await Axios.get('https://18.230.32.253:443/login',{
               const result = await Axios.get('https://api.solutionsti.info:443/login',{
                   // const result = await Axios.get('http://192.168.0.104:8080/login',{
                   //     const result = await Axios.get('https://localhost/login',{
                    auth: {
                        username : userName,
                        password : password
                    }}  
                ).then((result) => {
                tokenBack = result.data;
                tokenBack = JSON.stringify(tokenBack)
                tokenBack = JSON.parse(tokenBack)
                // GRAVA NO LOCALSTORAGE O TOKEN PARA SER ACESSADO NO MENU.
                localStorage.setItem('token', tokenBack.token)
                // localStorage.setItem('userName', userName)
                // VARIAVEL RENDERIZAÇÃO CONDICINAL, SE TRUE CHAMA <App />
                setApp(true)
                }).catch((error) => { modalErro(error)  //CHAMAR UMA MODAL EM VEZ DE ALERT
                
                // catch((error) => {
                //     alert(`${error} - Usuário e Senha Incorretos!`)
                })
            }

        }

            autenticaLogin()  

    }

    function modalErro(error){
        logOut(error)
        setMostraModalErro(true);
        tentativa = tentativa + 1;
    }   

    function logOut(error){

        if (error.message.includes('401')) {
           
            window.location = '/'
            localStorage.removeItem('token')
            setMsgErro('Sessão Expirada! Faça o Login Novamente.')
        }
        if (error.message.includes('404')){
        
            setMsgErro('EndPoint não Existe!')
        }
        if (error.message.includes('Network')){
            setMsgErro('Servidor Fora do ar, Contate o Administrador.')
        }
        if (error.message.includes('403')){
            setMsgErro('Usuário ou Senha Incorretos.')
        }
        else {
            setMsgErro(`${error.message}, Contate o Administrador!`)
            }   
    }
if (app) { return (

 
         
           
                
                    <App />
                
          
            



) }
else {
    return(
        <div>
            {/* <div className="container"> */}
                <div className="row justify-content-center align-items-center" >
                    <div className="col-4">
                      
                      <br />
                        <div >
                            <div >
                            <br /> 
                            <center>
                                {/* <img src={logo} width="450" height="110" alt=""/> */}
                                <img src={logo} width="380" height="100" alt=""/>
                            </center> 
                              <br />                          
                                <Form action="" autoComplete="off" >
                                    <div className="form-group">
                                        Usuário:
                                        <input type="text" style={{ fontSize: "20px"}}  onChange={(e) => setUserName(e.target.value.toUpperCase())} className="form-control" name="username"  />
                                    </div>
                                    <br/>
                                    <div className="form-group">
                                        Senha:
                                        <input type="password" onChange={(e) => setPassword(e.target.value)} class="form-control" name="password" />
                                    </div>
                                    <br/>
                                    <div className="d-grid gap-2">
                                        <button  type="button" class="btn btn-primary btn-lg btn-block" onClick={() => handleLogin()} >Entrar</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
            <Modal show={mostraModalErro}>
                <Modal.Header> 
                    <h4>{msgErro}</h4>
                </Modal.Header> 
                <Modal.Footer>
                    <Button type="button" onClick={() => setMostraModalErro(false)}>Fechar</Button>
                </Modal.Footer>
            </Modal> 
            </div>
       
        </div>
    )
}

}