import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {  Button, Form, Modal } from 'react-bootstrap';
import api from '../conexao.js';
import {useNavigate, Link} from 'react-router-dom';
import '../../App.css';
import logOut from '../logOut';

function Paciente() {

    const nave = useNavigate();

    const {register, handleSubmit} = useForm( );  //REGISTRANDO AS FUNÇÕES DO USEFORM
    const [paciente, setPaciente] = useState([]);  
    const [mostrarModal, setMostrarModal] = useState(false);
    const [pacdelete, setPacDelete] = useState();
    const [nomepaciente, setNomePaciente] = useState();
    const [MSG, setMSG] = useState();
    const [modalMSG, setModalMSG] = useState(false);
      
    const onSubmit = async (data) => {

    await api.get('/paciente', { 
      params: {           //BUSCA POR PARAMETRO ? NOME
        nome: data.NOME.toUpperCase()
        //banco: localStorage.getItem('banco')
      }}).then( result => {
      setPaciente(result.data) // joga a resposta em tPaciente
   }
      
      ).catch((error) => logOut(error)) // expirar
    }

    function handleCadPaciente(){
      nave('/paciente/cadpaciente');
     
      
    }

    function handleMostrarModal(){
      setMostrarModal(true);

    }

    function handleFecharModal(){
      setMostrarModal(false);
    }    


    async function handleExcluir(cod_delete){
             
        const response = await api.delete('/paciente/'+ cod_delete)
        handleFecharModal(false);
        // alert('Paciente Excluido!'); 
        setMSG('Paciente Excluido!');
        setModalMSG(true)  
        setPaciente([]) // zera o paciente para não mostrar mais nada na lista
}

    return (
      <div>
         
         <div className='Jumbotron'>
        <h1><center> Casdastro de Paciente </center></h1>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Control 
                  placeholder="Digite nome paciente"
                  type="text"
                  name="NOME"
                  Style="text-transform:uppercase;"
                  // {...register('value_name')}
                  {...register('NOME')}
                  required  
                  />
                  <br />
                  <Button variant="light" type="submit">
                          Buscar Pacientes </Button> <span />
                  <Button variant="success"  onClick={() => {handleCadPaciente()}}>Novo Paciente</Button>
                                           {/* defina type submit para que ele troca a route */}         
            </Form> 
         <br />
    <table className="table">
    <thead>
        <tr>
            <th scope="col">Cod.</th>
            <th scope="col">Nome</th>
            <th scope="col">Cidade</th>
            <th scope="col">Telefone</th>
            <th scope="col">Plano</th>
            <th scope="col"></th>
        </tr>
       
      </thead>
        <tbody>
          {paciente.map(customer => ( // percorre todos o array e joga na tela em formato de tabela
                              <tr key={customer.codigo}>
                                  <td>{customer.codigo}</td>
                                  <td>{customer.NOME}</td>
                                  <td>{customer.CIDADE}</td>
                                  <td>{customer.TEL}</td>
                                  <td>{customer.PLANO}</td>
                                  <td> 
                                    <Link to={"/paciente/editarpaciente/" + customer.codigo}>
                                           <Button 
                                              variant="warning" 
                                            >
                                            Editar
                                          </Button> 
                                          
                                        </Link>
                                      

                                  <span> </span>
                                  <Button variant="danger" onClick={ () => {
                                    setPacDelete(customer.codigo)
                                    setNomePaciente(customer.NOME)
                                    handleMostrarModal()
                                  }}>Excluir</Button></td>
                              </tr>
                          ))}
        </tbody>
        </table>
              
    
            </div>

            <Modal show={mostrarModal} backdrop="static">
              <Modal.Header>
                <h2> Excluir Paciente? </h2>
              </Modal.Header>
              <Modal.Body>
                  Excluir paciente: <strong>{nomepaciente}</strong>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={() => handleExcluir(pacdelete)}>Excluir</Button>
                <Button variant="secondary" onClick={() => handleFecharModal()}>Voltar</Button>
              </Modal.Footer>
            </Modal>

              <Modal show={modalMSG}>
                <Modal.Header> 
                    <h3>{MSG}</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
                </Modal.Footer>
            </Modal> 
      </div>
    );

}

export default Paciente; //retornar funcao sempre sem ()