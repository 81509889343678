import React, { useState, useEffect, Component } from 'react';
import { Modal, Button, Form} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Async, { makeAsyncSelect } from 'react-select/async';
import api from '../conexao.js';
import InputMask from 'react-input-mask';
import { Link, useParams, useNavigate } from 'react-router-dom';
import logOut from '../logOut';

export default function AgendaEditar(){

    const nave = useNavigate();
    const {id, idPac,encaixe} = useParams();

    // AgendaEditar.propTypes = {
    //     id: PropTypes.number.isRequired,
    //     idPac: PropTypes.number.isRequired    
    // }

    const {register, handleSubmit, setValue} = useForm( );  //REGISTRANDO AS FUNÇÕES DO USEFORM
    const jQuery = require('jquery');

    const [codPac, setCodPac] = useState();
    const [pacienteNome, setPacienteNome] = useState([]);
    const [hora, setHora] = useState();
    const [situacao, setSituacao] = useState([]);
    const [status, setStatus] = useState([]);
    const [mostrarModal, setMostrarModal] = useState(false);
    const [agenda, setAgenda] = useState([]);
    const [paciente, setPaciente] = useState([]);    
    const [dataAgendamento, setDataAgendamento] = useState();
    const [codMedico, setCodMedico] = useState();
    const [modalMSG, setModalMSG] = useState(false);
    const [MSG, setMSG] = useState();
 


 
 useEffect (() => {

  async function buscaDados() {

       await  api.get('/situacao').then (
             result => {
                 setSituacao(result.data);
             }
         ).catch(
            (error) => {
                setMSG(logOut(error))
                setModalMSG(true)
            }
        )  
     
      await   api.get('/status').then (
             result => {
                 setStatus(result.data);
                 
             }
         ).catch(
            (error) => {
                setMSG(logOut(error))
                setModalMSG(true)
            }
        )    

       await api.get('/agenda/'+ id).then (
            result => {
                setAgenda(result.data);
                setHora(result.data.HORA);
                setValue("VALOR", result.data.VALOR)
                setValue("SITUACAO", result.data.COD_SITUACAO)
                setValue("STATUS", result.data.COD_STATUS)
                setCodPac(result.data.COD_PACIENTE)
                setDataAgendamento(result.data.DATA)
                setCodMedico(result.data.COD_MEDICO)
                                
            }
        ).catch(
                (error) => {
                    setMSG(logOut(error))
                    setModalMSG(true)
                }
            )       
        await api.get('/paciente/' + idPac ).then (

            result => {
                setPaciente(result.data) 
            }
         ).catch(
            (error) => {
                setMSG(logOut(error))
                setModalMSG(true)
            }
        ) 
    }
    buscaDados();

 },[id]);


    const loadOptions = (inputValue, callback) => {
    
            
            if (!inputValue || inputValue.length <= 4)  {
                callback([])
            } else {
            setTimeout(() => {
                api.get('/paciente', { 
                    params: {           //BUSCA POR PARAMETRO ? NOME  
                    nome: inputValue
                    }}).then( result => {           // APARTIR DA 4 LETRA ELE COMEÇA A BUSCAR NO BANCO
                        const array = [];           // PENSAR EM UMA MANEIRA MELHOR PARA NAO FAZER VÁRIAS BUSCAS NA API
                        result.data.map(e  => (
                            array.push({label: `${e.NOME}`, value: `${e.codigo}`})
                            ));
                    callback(array)
                    
                    }).catch(
                        (error) => {
                            setMSG(logOut(error))
                            setModalMSG(true)
                        }
                    ) 
                }, 1000);
            }    
      };
        
    const onSubmit = (data) => {
          console.log(encaixe)
        const response = api.put('/agenda/'+ id,{
                COD_PACIENTE: parseInt(codPac),
                COD_STATUS: parseInt(data.STATUS),
                COD_SITUACAO: parseInt(data.SITUACAO),
                COD_MEDICO: parseInt(codMedico),
                DATA: dataAgendamento,
                HORA: hora,
                VALOR: parseFloat(data.VALOR),
                ENCAIXE: parseInt(encaixe)
        }).then(
            () => {
                setMostrarModal(true);
            });
      

    }    
    
    const handleChange = selectedOption => {
        setCodPac(selectedOption.value);
        setPacienteNome(selectedOption.label);          
    }
    
    function handleFecharModal(){
        setMostrarModal(false);
    }

    return (
        <div style={{backgroundColor: "#e6eff5"}}>
        <div className="w-50 p-3 mx-auto">
        <center><h4> Alterar Agendamento </h4> </center>
       
        <br />
        <Form onSubmit={handleSubmit(onSubmit)}>
   
             <div className="form-row">
            <div className="form-group col-md-12">
                Selecione o Paciente: 
                    <Async 
                        placeholder={paciente.NOME}
                        loadOptions={loadOptions}
                        onChange={handleChange}     
                        name="PACIENTE"                                           
                    />
                </div> 
            </div>           
            <div className="form-row">
                <div className="form-group col-md-2" >
                        Hora:
                        <br />
                        <InputMask  
                                mask="99:99:00" 
                                size="10" 
                                name="HORA"  
                                {...register('HORA')} 
                                value={hora}
                                onChange={(e) => setHora(e.target.value)}  // unica maneira para registrar o cep no State
                                alwaysShowMask={false}
                                
                        />                
                </div> 
            
                <div className="form-group col-md-5">
                    Procedimento:
                    <br />
                    <Form.Control as="select" size="sm"  name="SITUACAO" {...register('SITUACAO')}>
                            {situacao.map( sit => (
                                <option value={sit.CODIGO}>{sit.DESCRICAO}</option>
                            ))}
                    </Form.Control>
                </div>   
                <div className="form-group col-md-5">
                    Status
                    <br />
                    <Form.Control as="select" size="sm" name="STATUS" {...register('STATUS')}>
                            {status.map( sta => (
                                <option value={sta.CODIGO}>{sta.DESCRICAO}</option>
                            ))}
                    </Form.Control>
                </div> 
            </div>  
            <div className="form-row" >
                <div className="form-group col-md-2">
                        Valor
                        <Form.Control Style="text-transform:uppercase;" size="sm" type="text" name="VALOR" {...register('VALOR')}/>
                </div>  
            </div>
            <br />
                <div className="form-row" > 
                <div className="form-group col-md-1,5">               
                    <Button type="submit"   onClick={() => handleSubmit(onSubmit)}> Gravar </Button>
                </div>
                   <span> </span>
                <div className="form-group col-md-3" >               
                   <Link to="/agenda/agenda"> <Button variant="danger" > Cancelar  </Button> </Link>
                </div>
            </div>             
        </Form>
        
    </div>

    <Modal  show={mostrarModal} 
            onHide={handleFecharModal} 
            backdrop="static"
            >
            <Modal.Header>
                <center><h3>Agendamento Alterado</h3></center>
            </Modal.Header >
            <Modal.Footer>
            <Link to="/agenda/agenda"><Button variant="success" >Fechar</Button></Link>
            </Modal.Footer>
    </Modal>
    
    <Modal show={modalMSG}>
                <Modal.Header> 
                    <h3>{MSG}</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
                </Modal.Footer>
            </Modal>  
            </div>
    )

}