import React, { useState, useEffect} from 'react';
import { format} from 'date-fns';
import { Modal, Button, Form,Spinner } from 'react-bootstrap';
import api from '../conexao.js';
import { Link, useNavigate } from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faBook, faTrash, faBookMedical,faEdit} from "@fortawesome/free-solid-svg-icons"
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons"
import logOut from '../logOut';

export default function HorariosAgenda(props){

    const [hour, setHour] = useState();
    const [agenda, setAgenda] = useState([]);
    const [confAgenda, setConfAgenda] =useState([]);
    const [diaDeConsulta, setDiaDeConsulta] = useState([]); 
    var qtdHorarios;
    var qtdHoraAgenda;
    let objAgenda = [];
    let objAuxAgenda = [];
    var Encaixe = [];
    var auxEncaixe = [];
    const [exibirModal, setExibirModal] = useState(false);
    const [exibirModalZap, setExibirModalZap] = useState(false);

    const [agendaDelete, setAgendaDelete] = useState();
    const [btnExcluir, setBtnExcluir] = useState(false)
    const [dataVigor, setDataVigor] = useState();
    var dataFormated;
    
    const [modalMSG, setModalMSG] = useState(false);
    const [MSG, setMSG] = useState();
    const [numZap,setNumZap] = useState();
    const [textoZap, setTextoZap] = useState();
    const [loading, setLoading] = useState(false);

    const nave = useNavigate () //PARA NAVEGAR COM REACT ROUTER DOM 6.*

    function handleMostrarModal(cod_delete){
        setAgendaDelete(cod_delete)
        setExibirModal(true);
    }

    function handleFecharModal(){
        setExibirModal(false)
        

    }
    async function handleExcluir(cod_delete){
        const response = await api.delete('/agenda/'+ cod_delete);
     
        handleFecharModal(false); 
            
        props.click();

        // ATUALIZAR TELA DA AGENDA AO APAGAR AGENDAMENTO.

    }

    useEffect( () => {
              

        const SearchTimetable = (informa, result) => {
            
            var horaTer = new Date(`Mar 07 2024 ${informa.HORA_TER}`).getTime();
            var horaIni = new Date(`Mar 07 2024 ${informa.HORA_INI}`).getTime();

            var milisegundos = (horaTer - horaIni);
            
            var minutos = milisegundos / 60 /1000;

            qtdHorarios = (minutos ) / informa.TEMPO_CONSULTA; // quantidade de horarios
            
            // FOR PARA CRIAR OS HORARIOS VAZIOS DE ACORDO COM A QTD DE HORARIOS
            for (let i = 0; i < qtdHorarios+1; i++) {
                
                // PEGA CONSULTA                                               
                var auxHora = true;
                // TRANSFORMA MIN. EM MILI
                // MULTI POR i E SOMAR A HORA                                   
                //HORA = MILISEGUNDOS

                let hora = ( new Date(`Mar 07 2024 ${informa.HORA_INI}`).getTime() + (informa.TEMPO_CONSULTA*60*1000* i) )
                
                hora = hora - 10800000; 

                // VERIFICAR SE EXISTE INTERVALO
                
                if ((hora >= new Date(`Mar 07 2024 ${informa.HORA_INT_INI}`).getTime()
                
                - 10800000
                 ) &&
                    (hora < new Date(`Mar 07 2024 ${informa.HORA_INT_TER}`).getTime() 
                    
                    -10800000
                    ) )
                    {
                    auxHora = false
                    }
                
                if (auxHora) {
                
                  criaHorario(hora);
                    
                }
                //COLOCA UM IF QUE PEQUISA SE A HORA QUE ESTÁ ADICIONANDO JÁ EXISTE
                //EM result.data
                 
                    for (let a = 0; a < result.data.length ; a++) {
                        // ENCAIXE, VERIFICO SE O HORA DO BANCO DIVIDO POR TEMPO_CONSULTA RESULTA EM DECIMAL  EX:'2,3'
                        // SE SIM GRAVA NO ARRAY auxEncaixe, MAIS EM BAIXO JOGA PRO ARRAY Encaixe E TIRA OS DUPLICADOS


                        // ATUALIZAR PARA BASE CAMPO ENCAIXE
                        


                        let encaixeHora = new Date(`Mar 07 2024 ${result.data[a].HORA}`).getTime()
                       
                        encaixeHora = encaixeHora / 60 /1000; // TRANSFORMA EM MiNINUTO

                            // SE COMENTAR ABAIXO ELE TIRA O ERRO DE DUPLICAR
                            // POREM NAO AGENDA ENCAIXE
                            // CAMPO ENCAIXE AGORA NA BASE DE DADOS PARA NAO DAR ERRO DE REPETIR
                            console.log(result.data[a].ENCAIXE)
                            if (result.data[a].ENCAIXE == 1 ) {
                                auxEncaixe.push(result.data[a])
                            }
                        
                        if (result.data[a].HORA == convertMiliHora(hora)+':00')
                        {   
                            objAgenda = {
                                CODIGO : result.data[a].CODIGO,
                                COD_MEDICO : result.data[a].COD_MEDICO,
                                COD_PACIENTE : result.data[a].COD_PACIENTE,
                                COD_SITUACAO : result.data[a].COD_SITUACAO,
                                COD_STATUS : result.data[a].COD_STATUS,
                                DATA : result.data[a].DATA,
                                HORA : result.data[a].HORA,
                                Paciente: [{CODIGO: result.data[a].Paciente[0].CODIGO, NOME: result.data[a].Paciente[0].NOME, 
                                CEL: mascararCel(result.data[a].Paciente[0].CEL),
                                PLANO: result.data[a].Paciente[0].PLANO}],
                                Procedimento: [{DESCRICAO: result.data[a].Procedimento[0].DESCRICAO}],
                                Status: [{DESCRICAO: result.data[a].Status[0].DESCRICAO}],
                                VALOR: result.data[a].VALOR,
                                ENCAIXE: result.data[a].ENCAIXE                                     
                            }
                            objAuxAgenda.push(objAgenda)
                            
                        } 
                           
                    } 

                // //    APAGA OS DUPLICADOS E JOGA OS ENCAIXES NO ARRAY ENCAIXE
             
                    const teste = new Set(auxEncaixe);
                    Encaixe = Array.from(teste);
                 
            }  

                    // ARRAY FICA COM AS HORAS LIVRES E AS MARCAÇÕES, ESSA ROTINA APAGA ESSE DUPLICIDADE
            for (let o = 0; o < objAuxAgenda.length; o++) {
                
                if (o != 0) {// VERIFICA SE É O PRIMEIRO REGISTRO PARA NÃO APAGA-LO
                if(objAuxAgenda[o].HORA == objAuxAgenda[o-1].HORA){ // COMPARA SE A HORA É IGUAL A ANTERIOR
                    objAuxAgenda.splice(o-1,1); //APAGA A HORA ANTERIOR                                                
                } 
            }   
                
            }
        }        

        async function buscaAgenda() {
            setLoading(true) /// LOADING DO SPINNER , LÁ NO FINAL DA FALSE ELE SOME
            await api.get('/imprimeagenda', { 
                params: {           
                    med: props.codigoMed,
                    data: format(props.dia, 'yyyy-MM-dd') }
                }).then( result => {                    
                    async function buscaConfHorario() {
                        await api.get('/horarioagenda',{
                            params:{
                                codMed: props.codigoMed
                            }}
                        
                            ).then(lconfAgenda => {
                                    setConfAgenda(lconfAgenda.data) 
                                    //FILTRA lconfAgenda E PEGA O DIA DA SEMANA CORRETO GRAVA EM diaCons

                                        const orderDates = lconfAgenda.data.sort((a, b) => {
                                            const nuloA = a.DATA_VIGOR === null;
                                            const nuloB = b.DATA_VIGOR === null;

                                            if(nuloA && !nuloB){
                                                return -1;
                                            }else if(!nuloA && nuloB){
                                                return 1;
                                            }else{
                                                return 0;
                                            }
                                        });
                                    
                                        const diaCons = orderDates.filter( (x) => { 
                                            if(  x.DIA === props.dia.getDay() )
                                            {return x}
                                        })
                            
                                        setDiaDeConsulta(diaCons);
                                    
                                // verificação se existem items no array
                                if (diaCons.length > 0) {

            
                                    // analisa se o dia atual é o dia em vigor
                                    const dateVigor = diaCons.filter((item) => {
                                        
                                        return item.DATA_VIGOR === format(props.dia, 'yyyy-MM-dd');
                                    })

                                    // faz a verificação se o dia atual é um dia avulso
                                    if(dateVigor.length !== 0){
                                        SearchTimetable(dateVigor[0], result);
                                    }else if(diaCons[0].DATA_VIGOR === null){
                                        SearchTimetable(diaCons[0], result);
                                    }
                                    
                                }   // VERIFICA O TAMANHO DO ENCAIXE SENAO DA ERRO NA HORA JOGAR NA TELA
                                 if (Encaixe.length > 0) {
                                        for(let a = 0; a < Encaixe.length; a++ ){ // UM FOR PARA JOGA-LO NO objAuxAGenda
                                             objAuxAgenda.push(Encaixe[a]);
                                            }
                                    // } VERIFICAR SE JÁ EXISTE E APAGAR
                                                         
                                   }
                                   if (props.pEncaixe){
                                        
                                    objAuxAgenda.push(props.pEncaixe)
                                }
                                    objAuxAgenda.sort((HORA1, HORA2) => { // ORDENA LISTA POR HORA CRESCENTE
                                        HORA1 = HORA1.HORA;
                                        HORA2 = HORA2.HORA;
                            
                                        if (HORA1 > HORA2) {
                                            return 1;
                                        }
                                        if (HORA1 < HORA2) {
                                            return -1;
                                        } return 0;
                                    })

                                    // TIRA HORARIOS REPETIDOS
                                    
                                        objAuxAgenda.filter(function(este, i) {
                                            return objAuxAgenda.indexOf(este.HORA) === i.HORA;
                                        });
                                    

                                    // GRAVA NA VARIAVEL PRINCIPAL DA AGENDA
                                    //FIM

                                    setAgenda(objAuxAgenda);                     
                                }
                            
                            ).catch(
                                (error) => {
                                setMSG(logOut(error))
                                setModalMSG(true)
                                setLoading(false);  
                                    }
                                )
                            setLoading(false);    
            
                        } 
                        buscaConfHorario();
                }).catch(
                    (error) => {
                    setMSG(logOut(error))
                    setModalMSG(true)
                    setLoading(false);  
                    }
                )
                
            }   

            
            buscaAgenda();
            

            
            setAgenda(objAgenda);   // DEPOIS DE ATUALIZA PARA NODE 20.11, TENHO QUE ZERAR A AGENDA ANTES DE 
                                    // MOSTRAR, POIS ESTAVA SOMANDO COM O VALOR DO DIA ANTERIOR CLICADO.
            
        },[
             props.dia, props.codigoMed
        ]); 


            

    function isDecimal(num) {
        if(isNaN(num)) return false;             // false para não numéricos
        return parseInt(num) != parseFloat(num); // false para inteiros
    }
    function criaHorario(hr){
        objAgenda = {CODIGO : 0,
            COD_MEDICO : 0,
            COD_PACIENTE : 0,
            COD_SITUACAO : 0,
            COD_STATUS : 0,
            DATA : "",
            HORA : convertMiliHora(hr)+':00',
            Paciente: [{NOME: "", CEL: ""}],
            Procedimento: [{DESCRICAO: ""}],
            Status: [{DESCRICAO: ""}],
            VALOR: 0,
            ENCAIXE: 0                                       
        }   
        objAuxAgenda.push(objAgenda) 
        
    }

    function isFloat(n){
        if(!isNaN(n)) {
            if (parseInt(n) != parseFloat(n)) {
                return true;
            }
        }
        
        return false;
    }

    function retornaSeVazio(r){
        if (r == "") {
            return(true)
        } else
        if (r == isNaN){
            return(true)
        }
        else
        return(false)
    }
    
    function mascararCel(v){
        if (v) {
        v=v.replace(/\D/g,"");            
        v=v.replace(/^(\d{2})(\d)/g,"($1) $2"); 
        v=v.replace(/(\d)(\d{4})$/,"$1-$2"); 
        return v;
        }

    }

    function convertMiliHora(time) {        
        var millis= time % 1000;
        time = parseInt(time/1000);
        var seconds = time % 60;
        time = parseInt(time/60);
        var minutes = time % 60;
        time = parseInt(time/60);
        var hours = time % 24;
        var out = "";
        if(hours && hours > 0) out += hours + ((hours == 1)?"":"") + ":";
        if(minutes && minutes > 0) out += minutes + ((minutes == 1)?"":"") + "";
        if(seconds && seconds > 0) out += seconds + ((seconds == 1)?"":"") + "";
        if(millis&& millis> 0) out += millis+ " " + ((millis== 1)?"":"") + " ";
    
        if (out.substr(-1) == ":") {
            out = out += "00"
        }
        
        if (out.length === 4 
            // || out.length == 5
            ) {
                out = "0"+out;
            }
       
        return out.trim();

    
    } 
    function formataData(d){
        return(format(d,'yyyy-MM-dd'));
    }

    function chamaZap(n,nome,hr){
        var texto = 'Olá ' + nome + ',' + '\nGostaria de Confirmar sua Consulta no dia ' + transformaData(formataData(props.dia)) + ' às ' + hr + 'hs ?';
        setTextoZap(texto);
        setNumZap(n.replace(/[^0-9]/g,''));
        setExibirModalZap(true)
    }

    function encodeMsgZap(){
        
        return (window.encodeURIComponent(textoZap));
    }

    function transformaData(d){
        var date = d.split('-').reverse().join('/');   
        return(date)
    } 

    function navega(op, cod, codPac,codMed,data,hora,encai){
        //NAVIGATE FAZ COMO O <A href=> POREM JAVASCRIPT NAO HTML, TIVE QUE TROCAR POR 
        //ATAULIZAÇAO DO HOOKROUTER QUE O <A> PAROU DE ACEITAR DESABILITAR O BOTAO QUANDO NAO TEM PACIENTE
        
        if (!encai) { encai = 0}

        if (op == 2) {
             nave("/agenda/agendamento/" + parseInt(codMed) + "/" +  data  + "/" + hora + "/" + parseInt(encai)) 
       }
        if (op == 0) {
             nave("/agenda/agendaeditar/" + parseInt(cod) + "/" + parseInt(codPac) + "/" + parseInt(encai)) 
       } 
       if (op == 1) {
             nave("/fichapaciente/fichapaciente/" + parseInt(codMed) + "/" + parseInt(codPac)) 
       }
    }


return(
   

            <>
                
                    {loading && 
                    
                        <Spinner animation="border" variant="info" />
                    
                    }
                
                {                 
                agenda.map(agendamento => ( // percorre todo o array e joga na tela em formato de tabela
                            
                            
                            <tr  key={agendamento.CODIGO}>
                                <td >{agendamento.HORA}</td>
                                <td>{agendamento.Paciente[0].NOME}</td>
                                <td>{agendamento.Status[0].DESCRICAO}</td>
                                <td>{agendamento.Procedimento[0].DESCRICAO}</td>
                                <td>{agendamento.Paciente[0].PLANO}</td>
                                <td>{mascararCel(agendamento.Paciente[0].CEL)}</td>
                                
                                <td> 
                                    
                                  
                                <Button 
                                    className="btn btn-primary btn-sm"
                                    disabled={retornaSeVazio(agendamento.Paciente[0].NOME)}
                                    data-toggle="tooltip" data-placement="top" title="Editar Agendamento"
                                    onClick={()=> navega(0,agendamento.CODIGO,agendamento.COD_PACIENTE,0,0,0,agendamento.ENCAIXE)}
                                    >
                                    <FontAwesomeIcon icon={faEdit} size='lg'></FontAwesomeIcon>
                                </Button>
                                
                                    <span> </span>
                                
                                    <Button type="button" variant="danger" className="btn btn-primary btn-sm"
                                        disabled={retornaSeVazio(agendamento.Paciente[0].NOME)} 
                                        onClick={() => {handleMostrarModal(agendamento.CODIGO)}}
                                        data-toggle="tooltip" data-placement="top" title="Excluir"
                                    >
                                    <FontAwesomeIcon icon={faTrash } size='lg' ></FontAwesomeIcon>
                                    </Button>

                                    <span> </span>
                                    
                                    
                                        <Button type="button" variant="success" className="btn btn-primary btn-sm"  
                                            disabled={retornaSeVazio(agendamento.Paciente[0].NOME)}
                                            data-toggle="tooltip" data-placement="top" title="Ficha do Paciente"

                                             onClick={() =>  navega(1,0,agendamento.COD_PACIENTE,props.codigoMed,0,0,0)}
                                        >
                                        <FontAwesomeIcon icon={faBookMedical} size='lg' ></FontAwesomeIcon>  
                                        </Button>
                                    
                                    <span> </span>
                                    
                                        <Button 
                                            type="button" 
                                            className="btn btn-primary btn-sm"
                                            disabled={!retornaSeVazio(agendamento.Paciente[0].NOME)}
                                            data-toggle="tooltip" data-placement="top" title="Agendar"
                                            onClick={() => navega(2,0,0,props.codigoMed,formataData(props.dia),agendamento.HORA.replace(/:/g,'-'),
                                                     agendamento.ENCAIXE)}
                                        >
                                            <FontAwesomeIcon  icon={faBook} size='lg' ></FontAwesomeIcon>
                                        </Button>
                                    
                                    <span> </span>
                                        <Button 
                                            type="button" 
                                            variant="success"
                                            className="btn btn-sm"
                                            disabled={retornaSeVazio(agendamento.Paciente[0].CEL)}
                                            data-toggle="tooltip" data-placement="top" title="WhatsApp"
                                            onClick={() => { 
                                                chamaZap(agendamento.Paciente[0].CEL, agendamento.Paciente[0].NOME,
                                                        agendamento.HORA)}
                                            }
                                        >
                                            <FontAwesomeIcon  icon={faWhatsapp} size='lg' />  
                                        </Button>
                                    
                                </td>
                            </tr>
                            
            ))}

            
            <Modal show={exibirModal} backdrop="static">
                <Modal.Header>
                    <h3>Deseja Excluir o Agendamento?</h3>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => handleExcluir(agendaDelete)}>Excluir</Button>
                    <Button variant="secondary" onClick={() => handleFecharModal()}>Voltar</Button>    
                </Modal.Footer>
            </Modal>

          

            <Modal show={exibirModalZap} backdrop="static">
                <Modal.Header>
                    <h3>Confirmação via WhatsApp</h3>
                </Modal.Header>
                <Modal.Body>
                    Celular WhatsApp:
                    <br/>
                    <input type="text" name="numZap" value={numZap}  onChange={(e)=> {setNumZap(e.target.value)}}/>
                    <br/>
                    Mensagem:
                    <br/>
                    <textarea name="msgZap" id="msgZap"  
                    value={textoZap}
                    onChange={(e)=> {setTextoZap(e.target.value)}}
                     cols="50" rows="05">
                        {textoZap}
                    </textarea>
                </Modal.Body>
                <Modal.Footer>
                <Link to={'https://wa.me/55' + numZap + '?text=' + encodeMsgZap(textoZap)} target="_blank"> 
                    <Button variant="danger" onClick={() => setExibirModalZap(false) }>Enviar</Button>
                </Link>
                    <Button variant="secondary" onClick={() => setExibirModalZap(false)}>Voltar</Button>    
                </Modal.Footer>
            </Modal>

            <Modal show={modalMSG}>
                <Modal.Header> 
                    <h3>{MSG}</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
                </Modal.Footer>
            </Modal>  

         </>


)

}

