import React, { useState, useEffect }from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Button, Form} from 'react-bootstrap';
import api from '../conexao.js';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { useParams, useNavigate } from "react-router-dom";
import { parseISO } from 'date-fns'; //erro de tipo time no datepicker - resolvido 
import InputMask from 'react-input-mask';
import '../../App.css';
import logOut from '../logOut';


export default function EditarPaciente(){

        const {id} = useParams();

// EditarPaciente.propTypes = {
//     id: PropTypes.number.isRequired
// }

const {register, handleSubmit, setValue} = useForm( );  //REGISTRANDO AS FUNÇÕES DO USEFORM
const [datanasc, setDataNasc] = useState();
const [exibirModal, setExibirModal] = useState(false); // const para definir se a modal aparece ou nao
const [pacNome, setPacNome] = useState();
const [cep, setCEP] = useState();
const [tel, setTEL] = useState();
const [cel, setCEL] = useState();
const [cpf, setCPF] = useState();
const [cpf_conjuge, setCPF_CONJUGE] = useState();
const [modalMSG, setModalMSG] = useState(false);
const [MSG, setMSG] = useState();

const nave = useNavigate () //PARA NAVEGAR COM REACT ROUTER DOM 6.*


var cepnum = String;
var telnum = String;
var celnum = String;
var cpfnum = String;
var cpfconjnum = String;

useEffect( () => {
    api.get('/paciente',{
    params: {           //BUSCA POR PARAMETRO ? NOME
        codigo: id,
        banco: localStorage.getItem('banco')
      }}).then(result => {
           setValue("NOME", result.data.NOME);
           setValue("SEXO", result.data.SEXO);
           setValue("ENDERECO", result.data.ENDERECO);
           setValue("BAIRRO", result.data.BAIRRO);
           setValue("CIDADE", result.data.CIDADE);
           setValue("IDENT", result.data.IDENT);
           setValue("CONJUGUE", result.data.CONJUGUE);
           setValue("PLANO", result.data.PLANO);
           setValue("NATURALIDADE", result.data.NATURALIDADE);
           setValue("PROFISSAO", result.data.PROFISSAO);
           setValue("INDICADOPOR", result.data.INDICADO_POR);
           setValue("OBS", result.data.OBS);
           setValue("COD_EC", result.data.COD_EC);
                  
             if (result.data.DATA_NASC === null) { // corrige datas vazias que vem da base antiga
                        setDataNasc(null) // nao sei o q q eu fiz mais funcionou
             } else {setDataNasc(parseISO(result.data.DATA_NASC))} //erro de tipo time no datepicker - resolvido com parseISO        

             setCEP(result.data.CEP.toString());
             setValue("CEP", cep);

             setTEL(result.data.TEL.toString());
             setValue("TEL", tel);

             setCEL(result.data.CEL.toString());
             setValue("CEL", cel);

             setCPF(result.data.CPF.toString());
             setValue("CPF", cpf);             

             setCPF_CONJUGE(result.data.CPF_CONJUGE.toString());
             setValue("CPF_CONJUGE", cpf_conjuge);             

    }).catch((error) => logOut(error))   
}, [id]);



function handleFecharModal(){
        setExibirModal(false); // inicia faalse
        nave('/paciente/paciente'); // vai para pagina anterior
        
}

function handleVoltar(){
        nave('/paciente/paciente');
        
}



 const onSubmit = (data) => {
        
        // cepnum = cep;
        // cepnum = cepnum.replace(/[^0-9.]/g, ""); // remove "-"" do CEP

        if (cep) {
                cepnum = cep;
                cepnum = cepnum.replace(/[^0-9]+/g, ""); // remove o que nao é numero do CEP
        }
        if  (tel)  {
                telnum = tel;
                telnum = telnum.replace(/[^0-9]+/g, "");
        }
        if (cel) {
                celnum = cel;
                celnum = celnum.replace(/[^0-9]+/g, "");
        }
        if (cpf) {
                cpfnum = cpf;
                cpfnum = cpfnum.replace(/[^0-9]+/g, "");
        }
        if (cpf_conjuge) {
                cpfconjnum = cpf_conjuge;
                cpfconjnum = cpfconjnum.replace(/[^0-9]+/g, "");
        }


        const response = api.put('/paciente/'+ id, {
                COD_EC: parseInt(data.COD_EC),
                NOME:  data.NOME.toUpperCase(),
                SEXO: data.SEXO.toUpperCase(),
                ENDERECO: data.ENDERECO.toUpperCase(),
                BAIRRO: data.BAIRRO.toUpperCase(),
                CIDADE: data.CIDADE.toUpperCase(),
                CEP: cepnum,
                TEL: telnum,
                CEL: celnum,
                CPF: cpfnum,
                IDENT: data.IDENT.toUpperCase(),
                DATA_NASC: datanasc,
                CONJUGUE: data.CONJUGUE.toUpperCase(),
                CPF_CONJUGE: cpfconjnum,
                PLANO: data.PLANO.toUpperCase(),
                NATURALIDADE: data.NATURALIDADE.toUpperCase(),
                PROFISSAO: data.PROFISSAO.toUpperCase(),
                INDICADO_POR: data.INDICADOPOR.toUpperCase(),
                OBS: data.OBS
        }).catch(        (error) => {
                setMSG(logOut(error))
                setModalMSG(true)
            })
        .then(
        setPacNome(data.NOME),
        setExibirModal(true)
        )
        
    }

return (

            
        <div className="w-90 p-3 mx-auto">
          
            <div className='Jumbotron'>
                <center> <h1> Editar Paciente </h1> </center>
            <br />
            <div>
        <Form onSubmit={handleSubmit(onSubmit)}  >
          <div className="w-90 p-3 mx-auto">
            <div className="form-row">
                <div className="form-group col-md-6">
                        Nome:
                        <Form.Control Style="text-transform:uppercase;" 
                        size="sm" 
                        type="text"  
                        name="NOME"
                        maxLength={45}  
                        {...register('NOME')}
                        />
                </div>
        
                <div className="form-group col-md-2">
                        Sexo:
                        <Form.Control  size="sm" as="select" name="SEXO" {...register('SEXO')}>
                            <option value="M">MASCULINO</option>
                            <option value="F">FEMININO</option>                
                        </Form.Control>
                </div>
            </div>
            <div className="form-row">
            <div className="form-group col-md-6">
                        Endereço:
                        <Form.Control Style="text-transform:uppercase;" maxLength={45} size="sm" type="text" name="ENDERECO"  {...register('ENDERECO')}/>
                </div> 
                <div className="form-group col-md-6">
                        Bairro:
                        <Form.Control Style="text-transform:uppercase;" maxLength={45} size="sm" type="text" name="BAIRRO" {...register('BAIRRO')}/>
                </div>   
            </div>

            <div className="form-row">
            <div className="form-group col-md-6">
                        Cidade:
                        <Form.Control Style="text-transform:uppercase;" maxLength={45} size="sm" type="text" name="CIDADE" {...register('CIDADE')}/>
                </div> 
                <div className="form-group col-md-2">
                        CEP:
                        <br/>
                        <InputMask  
                                mask="99999-999" 
                                size="8" 
                                name="CEPi"  
                                // {...register('CEPi')} 
                                value={cep}
                                onChange={(e) => setCEP(e.target.value)}  // unica maneira para registrar o cep no State
                                alwaysShowMask={false}
                        />

                </div>   
            </div>
            <div className="form-row">
            <div className="form-group col-md-3">
                        Tel.:
                        <br/>
                        <InputMask
                                mask="(99)9999-9999"
                                size="24"
                                name="TEL"
                                {...register('TEL')} 
                                value={tel}
                                onChange={(e) => setTEL(e.target.value)}
                                alwaysShowMask={false}
                        />

                </div> 
                <div className="form-group col-md-3">
                        Cel.:
                        <br/>
                        <InputMask
                                mask="(99)99999-9999"
                                size="24"
                                name="CEL"
                                {...register('CEL')} 
                                value={cel}
                                onChange={(e) => setCEL(e.target.value)}
                                alwaysShowMask={false}
                        />
                </div>
                <div className="form-group col-md-3">
                        CPF:
                        <br />
                        <InputMask
                                mask="999.999.999-99"
                                size="24"
                                name="CPF"
                                {...register('CPF')} 
                                value={cpf}
                                onChange={(e) => setCPF(e.target.value)}
                                alwaysShowMask={false}
                        />
                </div>  
            </div>

            <div className="form-row">
            <div className="form-group col-md-2">
                        RG:
                <Form.Control Style="text-transform:uppercase;" maxLength={15} size="sm" type="text" name="IDENT" {...register('IDENT')} />
                </div> 
                <div className="form-group col-md-3">
                        Data Nascimento:
                        <br/>
                        <DatePicker                         
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Selecione a data"
                        selected={datanasc}
                        onChange={setDataNasc}
                        name="DATA_NASC"
                        // {...register('DATA_NASC')}
                        /> 
                        
                        
                </div>
                <div className="form-group col-md-4">
                        Estado Civil:
                        <Form.Control Style="text-transform:uppercase;" as="select" name="COD_EC" size="sm" {...register('COD_EC')}>
                            <option value="2">CASADO(A)</option>
                            <option value="4">DIVORCIADO(A)</option>
                            <option value="6">NENHUM</option>
                            <option value="5">SEPARADO(A)</option>
                            <option value="1">SOLTEIRO(A)</option>
                            <option value="3">VIÚVO(A)</option>
                        </Form.Control>
                </div>  
            </div>

            <div className="form-row">
            <div className="form-group col-md-4">
                        Conjuge:
                        <Form.Control Style="text-transform:uppercase;" maxLength={45}  size="sm" type="text"   name="CONJUGUE" {...register('CONJUGUE')}/>
                </div> 
                <div className="form-group col-md-3">
                        CPF Conjuge:
                        <br/>
                        <InputMask
                                mask="999.999.999-99"
                                size="24"
                                name="CPF_CONJUGE"
                                {...register('CPF_CONJUGE')}
                                value={cpf_conjuge}
                                onChange={(e) => setCPF_CONJUGE(e.target.value)}
                                alwaysShowMask={false}
                        />
                        
                </div>
                <div className="form-group col-md-4">
                        Plano:
                        <Form.Control Style="text-transform:uppercase;" maxLength={45}  size="sm" type="text" name="PLANO" {...register('PLANO')}/>
                </div>  
            </div>

            <div className="form-row">
                <div className="form-group col-md-4">
                        Natualidade:
                        <Form.Control Style="text-transform:uppercase;" maxLength={45}  size="sm" type="text" name="NATURALIDADE" {...register('NATURALIDADE')}/>
                </div> 
                <div className="form-group col-md-4">
                        Profissão:
                        <Form.Control Style="text-transform:uppercase;"  maxLength={45} size="sm" type="text" name="PROFISSAO"  {...register('PROFISSAO')}/>
                </div>
                <div className="form-group col-md-4">
                        Indicado por:
                        <Form.Control Style="text-transform:uppercase;" maxLength={45}  size="sm" type="text" name="INDICADOPOR" {...register('INDICADOPOR')}/>
                </div>  
            </div>
            <div className="form-row">
                <div className="form-group col-md-8">
                        Observação:
                        <Form.Control Style="text-transform:uppercase;" maxLength={120}  as="textarea" rows="3" size="sm" name="OBS" {...register('OBS')}/>
                </div> 

            </div>
            <br />
            <div className="form-row" >
                <div className="form-group col-md-10">
                    <Button 
                    className="btn btn-primary" 
                    type="submit" 
                    >
                        Gravar</Button>
                {/* </div>
                <div className="form-group col-md-2"> */}
              
                    <Button type="button"
                         
                    className="btn btn-secondary" 
                    onClick={() => {handleVoltar()}}
                    >
                Cancelar</Button>
                </div>    
            </div>   
          </div> 
            </Form> 
        </div> 
            </div> 
        <Modal show={exibirModal} onHide={handleFecharModal} backdrop="static">
                <Modal.Header>
                        <h2> Alteração feita com Sucesso! </h2>
                </Modal.Header>
                <Modal.Body>
                  Paciente: <strong>{pacNome}</strong>, Alterado!                  
                </Modal.Body>
                <Modal.Footer>
                        <Button variante="success" onClick={() => handleFecharModal()}> Ok </Button>
                </Modal.Footer>
                
        </Modal>
        <Modal show={modalMSG}>
                <Modal.Header> 
                    <h3>{MSG}</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
                </Modal.Footer>
            </Modal> 
        
        </div>
 

                )

}



