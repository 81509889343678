import React, { useState, useEffect } from 'react';
import { Modal, Button, Form} from 'react-bootstrap';
import Async from 'react-select/async';
import api from '../conexao.js';
import { Link } from 'react-router-dom';
import logOut from '../logOut';

export default function FichaPacienteAcesso(){

    const [codPaciente, setCodPaciente] = useState();
    const [pacienteNome, setPacienteNome] = useState();
    const [codMed, setCodMed] = useState();
    const [medicos, setMedicos] = useState([]);


useEffect( () => {   

 async   function buscaMedicos() {

      await  api.get('/medico').then(listamedicos => {   
            setMedicos(listamedicos.data)
            setCodMed(listamedicos.data[0].CODIGO)

        }).catch((error) => logOut(error))  
    }
    buscaMedicos();
},[]);   


const loadOptions = (inputValue, callback) => {
        
        if (!inputValue || inputValue.length <= 4)  {
            callback([])
        } else {
        setTimeout(() => {
            api.get('/paciente', { 
                params: {           //BUSCA POR PARAMETRO ? NOME  
                nome: inputValue
                }}).then( result => {           // APARTIR DA 4 LETRA ELE COMEÇA A BUSCAR NO BANCO
                    const array = [];           // PENSAR EM UMA MANEIRA MELHOR PARA NAO FAZER VÁRIAS BUSCAS NA API
                    result.data.map(e  => (
                        array.push({label: `${e.NOME}`, value: `${e.codigo}`})
                        ));
                callback(array)
                
                })
            }, 1000);
        }    
};

const handleChange = selectedOption => {
    setCodPaciente(selectedOption.value);
    setPacienteNome(selectedOption.label);
}

    return(
    <div style={{backgroundColor: "#e6eff5", padding: "10px"}}>
        <Form>
            
        <div className="container">
            <br /> 
        <h3>Ficha Paciente</h3>
        <div className="form-row">
            
            <div className="form-group col-md-4">
            <br />
                Selecione o Médico:
                
                <Form.Control   as="select" value={codMed} onChange={e => setCodMed(e.target.value)} 
                        >
                            {medicos.map( med => (
                                <option value={med.CODIGO}>{med.NOME}</option>
                            ))}
                        </Form.Control> 
                </div> 
                <div className="form-group col-md-6">
                <br />
                Selecione o Paciente:                 
                    <Async 
                        placeholder="Digite o nome do paciente!"
                        loadOptions={loadOptions}
                        onChange={handleChange}                        
                    />
                </div> 

            </div> 
            <Link to={"/fichapaciente/fichapaciente/" + parseInt(codMed) + "/" + parseInt(codPaciente)}>
            <Button size="lg">
                    Avançar
                </Button>  
                </Link>   
            </div> 
        </Form>
    </div>
    
    )
};

