import React from 'react';
import {useState, useEffect} from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import api from '../conexao.js';
import { format } from 'date-fns';
import logOut from '../logOut';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';

 export default function ConfAgenda(){

    const [codMed, setCodMed] = useState(1) //começa com 1 para abrir junto com useEffect
    const [medicos, setMedicos] = useState([])
    const [diaAtend, setDiaAtend] = useState('SEGUNDA')
    const [horaInicio, setHoraInicio] = useState()
    const [horaTermino, setHoraTermino] = useState()
    const [horaInterInicio, setHoraInterInicio] =useState();
    const [horaInterTermino, setHoraInterTermino] =useState();
    const [diaAvulso, setDiaAvulso] = useState();
    const [dia,setDia] = useState()
    const [diaInteiro, setDiaInteiro] = useState();
    const [duracao, setDuracao] = useState();
    const [confAgenda, setConfAgenda] = useState([]);
    let checkbox = document.getElementById('dia avulso');
    const [chk, setCHK] = useState(true);
    const [modalMSG, setModalMSG] = useState(false);
    const [MSG, setMSG] = useState();


    useEffect( () => {   

        async   function buscaMedicos() {
       
             await  api.get('/medico').then(listamedicos => {   
                   setMedicos(listamedicos.data)
                   setCodMed(listamedicos.data[0].CODIGO)
       
               }).catch((error) => logOut(error))   
           }
           
           buscaMedicos();          
           buscaConfAgenda(codMed)

    },[]);  


    async function buscaConfAgenda(codMed){
        setCodMed(codMed)
        // console.log(confAgenda)
        await api.get('/horarioagenda',{
        params:{
            codMed: codMed
        }}
    
        ).then(lconfAgenda => {
                setConfAgenda(lconfAgenda.data) 
                

        }).catch((error) => logOut(error))   


    }

    function confereDia (diaExtenso,dia){

        if (dia) {
        handleGrava()
       } else {
                const verifDiaExiste = confAgenda.filter( (teste) => teste.DIA_EXTENSO 
                === diaExtenso && teste.DATA_VIGOR === null );
                
                if (verifDiaExiste.length == 0) {
                    handleGrava()
                } else {
                    
                    setMSG('Já Existe esse dia da Semana Cadastrado!')
                    setModalMSG(true)
                }
        }
    }
    
    async function handleGrava(){

            //DAR UM GET, BUSCAR SE EXISTE O DIA COMUM JÁ EXISTE, E PERGUNTAR 
            //SE DESEJA SUBSTITUIR;

            

            // CHAMAR OUTRA FUNCÃO PARA FAZER A VERIFICAÇÃO ANTES DA FUNÇÃO GRAVAR.

            api.post('/horarioagenda', {
                CODIGO: 1,
                COD_MEDICO: parseInt(codMed),
                HORA_INI: horaInicio,
                HORA_TER: horaTermino,
                HORA_INT_INI: horaInterInicio,
                HORA_INT_TER: horaInterTermino,
                TEMPO_CONSULTA: parseInt(duracao),
                DATA_VIGOR : dia,
                DIA : diaInteiro,
                DIA_EXTENSO : diaAtend

            }).catch((error) => logOut(error)) 
            .then( () => {
                buscaConfAgenda(codMed)
                
                
            })
         
            setMSG('Gravado')
            setModalMSG(true)

    }

    async function handleExcluir(cod_delete){
        const response = await api.delete('/horarioagenda/'+ cod_delete);
        buscaConfAgenda(codMed)
        
        setMSG('Horário Excluido!')
        setModalMSG(true)
    }

    function desativaDarePiker(){
        if (chk) {
                setCHK(false)
        } else {setCHK(true) 
                setDia()
            } 
    }
        
    function SetaDiaAtend(d){

        setDiaAtend(d);
        
        if (d === 'SEGUNDA'){setDiaInteiro(1)};
        if (d === 'TERCA'){setDiaInteiro(2)};
        if (d === 'QUARTA'){setDiaInteiro(3)};
        if (d === 'QUINTA'){setDiaInteiro(4)};
        if (d === 'SEXTA'){setDiaInteiro(5)};
        if (d === 'SABADO'){setDiaInteiro(6)};    
        
    }


    return (

        <div>
            <div style={{backgroundColor: "#e6eff5", padding: "10px"}}>
            <br />
            <div className="container">
                <h3>Configurar Agenda</h3>
                <br></br>
                    <Form>
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                    Selecione o Médico:
                                    <Form.Control  size="sm" as="select" value={codMed} onChange={e => buscaConfAgenda(e.target.value)} >
                                    <option disabled selected value>Selecione o Médico</option>
                                        {medicos.map( med => (
                                            
                                            <option value={med.CODIGO}>{med.NOME}</option>
                                        ))}
                                        {medicos.CODIGO}
                                    </Form.Control>
                                </div>
                                
                                <div className="form-group col-md-4">
                                            Dia Atendimento:
                                            <Form.Control size="sm" as="select" name="DIA" 
                                            // value={diaAtend}
                                             onChange={e => SetaDiaAtend(e.target.value)}>
                                                <option disabled selected value>Dia da Semana</option>
                                                <option value="SEGUNDA">SEGUNDA</option>
                                                <option value="TERCA">TERÇA</option>                
                                                <option value="QUARTA">QUARTA</option>                
                                                <option value="QUINTA">QUINTA</option>                
                                                <option value="SEXTA">SEXTA</option>                
                                                <option value="SABADO">SÁBADO</option>                
                                            </Form.Control>
                                    </div>          
                                    <div className="form-group col-md-2">
                                        Início:
                                        <br />
                                        <input type="time"
                                                name="horainicio"
                                                value={horaInicio}
                                                onChange={(e) => setHoraInicio(e.target.value)}
                                                step="2"
                                        ></input>

                                     </div>
                                    <div className="form-group col-md-2">
                                        Término:
                                        <br />
                                        <input type="time"
                                                name="horatermino"
                                                value={horaTermino}
                                                onChange={(e) => setHoraTermino(e.target.value)}
                                                step="2"
                                        ></input>
 
                                    </div>
                             
                                    <div className="form-group col-md-2">
                                        Intervalo Início:
                                        <br />
                                        <input type="time"
                                                name="horaInterInicio"
                                                value={horaInterInicio}
                                                onChange={(e) => setHoraInterInicio(e.target.value)}
                                                step="2"
                                        ></input>
       
                                    </div>
                                    <div className="form-group col-md-2">
                                        Intervalo Término:
                                        <br />
                                        <input type="time"
                                                name="horaInterTermino"
                                                value={horaInterTermino}
                                                onChange={(e) => setHoraInterTermino(e.target.value)}
                                                step="2"
                                        ></input>

                                    </div>
                                    <div className="form-group col-md-2">
                                            Duração(Minutos):
                                            <Form.Control size="sm" type="text" name="Duracao" value={duracao} onChange={(e) => {setDuracao(e.target.value)} } />
                                    </div> 
                                    <spam></spam>
                                    <div className="form-group col-md-2">
                                        <Form.Check 
                                            type="switch"
                                            id="dia avulso"
                                            label="Dia Avulso"
                                            value="1"
                                            onChange={(e) => desativaDarePiker()}
                                        /> 
                                    </div> 
                                    <div className="form-group col-md-4">
                                            Dia:
                                            <DatePicker                         
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="Selecione a data"
                                            selected={dia}
                                            onChange={setDia}                         
                                            name="DIA"
                                            disabled={chk}
                                            /> 
                                    </div>                  
                                    <div className="form-group col-md-10">
                                        <Button 
                                            className="btn btn-primary" 
                                            
                                            onClick={() => {confereDia(diaAtend,dia)}}
                                            >
                                        Gravar
                                        </Button>
                                    </div>
                                     


                            </div>  
                    </Form>

    <table className="table" style={{fontSize: "85%"}}>
    <thead>
        <tr>
            <th scope="col">Dia da Semana</th>
            <th scope="col">Duração</th>
            <th scope="col">Hora Início</th>
            <th scope="col">Hora Termino</th>
            <th scope="col">Hora Intervalo Início</th>
            <th scope="col">Hora Intervalo Termino</th>
            <th scope="col">Dia</th>
            <th scope="col"></th>
        </tr>
       
      </thead>
        <tbody>
        { 
        

            
            

          confAgenda.map(conf => ( // percorre todos o array e joga na tela em formato de tabela
                                        
                              <tr key={conf.CODIGO}>
                                  <td>{conf.DIA_EXTENSO}</td>
                                  <td>{conf.TEMPO_CONSULTA}</td>
                                  <td>{conf.HORA_INI}</td>
                                  <td>{conf.HORA_TER}</td>
                                  <td>{conf.HORA_INT_INI}</td>
                                  <td>{conf.HORA_INT_TER}</td>
                                  <td> {conf.DATA_VIGOR &&
                                      conf.DATA_VIGOR.split('-').reverse().join('/')}</td>                        
                                  <td> 
                                  
                                  <Button variant="danger" onClick={ () => {
                                        handleExcluir(conf.CODIGO)
                                  }}>Excluir</Button></td>
                              </tr>
                          ))}
        </tbody>
        </table>  


    </div>
                                   
            </div>
            <Modal show={modalMSG}>
                <Modal.Header> 
                    <h3>{MSG}</h3>
                </Modal.Header> 
                <Modal.Footer>
                    <Button variant="info" onClick={() => setModalMSG(false)}>Ok</Button>
                </Modal.Footer>
            </Modal>  
        </div>
        
    );
 }